import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";

const bootstrap = require("bootstrap");

export const ModalVariant = {
  fullscreen: "modal-fullscreen",
  xl: "modal-xl",
  lg: "modal-lg",
  md: "",
  sm: "modal-sm",
};

export const ModalFooter = ({ children }) => (
  <div className="modal-footer">{children}</div>
);

export const toggleModal = (modalId) => {
  // eslint-disable-next-line no-undef
  const myModal = new bootstrap.Modal(document.getElementById(modalId), {
    keyboard: false,
  });
  myModal?.toggle();
};

export const hideModal = (modalId) => {
  // eslint-disable-next-line no-undef
  $(`#${modalId}-close-button`).click();
};

export class ModalManager {
  constructor(id) {
    this.id = id;
  }

  toggle() {
    if (this.isVisible()) return;
    toggleModal(this.id);
  }

  hide() {
    if (!this.isVisible()) return;
    hideModal(this.id);
  }

  isVisible() {
    return document.getElementById(this.id)?.className?.includes("show");
  }
}

const Modal = ({
  title,
  id,
  variant = "modal-fullscreen-sm-down",
  children,
  footer,
  className,
  onHide,
  onShow,
  staticBackdrop,
}) => {
  const ref = useRef();
  const [showChildren, setShowChildren] = useState(false);
  const location = useLocation();

  useEffect(() => {
    hideModal(id);
    const modal = new bootstrap.Modal(document.getElementById(id));
    modal.hide();
  }, [location]);

  useEffect(() => {
    // Only show children when the modal is visible to prevent unnecessary API calls
    const eventHandler = (e) => {
      setShowChildren(true);
      onShow && onShow(e);
    };
    ref.current?.addEventListener("show.bs.modal", eventHandler);
    if (onHide) {
      ref.current?.addEventListener("hide.bs.modal", onHide);
    }

    return () => {
      ref.current?.removeEventListener("show.bs.modal", eventHandler);
      if (onHide) {
        ref.current?.removeEventListener("hide.bs.modal", onHide);
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      className="modal fade"
      data-bs-backdrop={staticBackdrop ? "static" : null}
      id={id}
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className={`modal-dialog ${variant}`}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            <button
              hidden={!!staticBackdrop}
              id={`${id}-close-button`}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className={`modal-body ${className}`}>
            {showChildren && children}
          </div>
          {footer}
        </div>
      </div>
    </div>
  );
};
export default Modal;

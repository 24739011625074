import React, { useEffect } from "react";

import Modal, { ModalVariant, toggleModal } from "../components/Modal";
import SimpleForm from "../components/Form/SimpleForm";
import { SettingsFactory } from "../components/Settings/SettingsFactory";
import { get, put } from "../lib/api";
import { accountNameField, einField } from "./CommonAccountFields";
import ExternalLink from "../components/ExternalLink";
import { SupportAnchor } from "../lib/ikeono";
import { LocalStorageKey } from "../lib/localStorageKey";
import { useState } from "../lib/overmind";

export const AccountEinUpdateModalId = "account-ein-update-modal";

const fields = [
  accountNameField,
  einField,
  {
    label: "Company Website",
    body: "",
    props: {
      name: "website",
      required: true,
    },
  },
  {
    label: "Address",
    body: "The physical address of your business.",
    as: "address",
  },
];

const AccountEinUpdateModal = () => {
  const state = useState().account;
  const apiGetHandler = () => get("/shop/{exid}/account/primary");

  const apiSubmitHandler = async (data) => {
    await put("/shop/{exid}/account/primary", data);
  };

  useEffect(() => {
    if (!state.data.ui_metadata.show_tcr_info_modal || state.country !== "US") {
      return;
    }

    toggleModal(AccountEinUpdateModalId);
  }, []);

  return (
    <Modal
      title="Account Details"
      id={AccountEinUpdateModalId}
      variant={ModalVariant.xl}
      staticBackdrop
    >
      <div className="mx-3 my-2 d-flex gap-5">
        <div style={{ maxWidth: "40ch" }} className="alert alert-light">
          <h5>What you need to know:</h5>
          <p>
            Due to regalatory changes you need to complete your account profile.{" "}
            <ExternalLink href="https://www.ikeono.com/blog/a2p-10dlc">
              More information
            </ExternalLink>
          </p>
        </div>
        <div className="w-100">
          <SimpleForm
            apiGetHandler={apiGetHandler}
            apiSubmitHandler={apiSubmitHandler}
          >
            <SettingsFactory fields={fields} />
          </SimpleForm>
        </div>
      </div>
    </Modal>
  );
};

export default AccountEinUpdateModal;
